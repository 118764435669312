.wpwl-form-card
{
min-height: 0px !important;
}


.wpwl-button-pay{
    background-color: #5997F1 !important;
    border: 1px solid #000 !important;
    color: #FFF !important;
    font-size: 17px !important;
    font-weight: 600 !important;
    padding: 10px 20px !important;
    border-radius: 5px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    width: 40% !important;
    text-align: center !important;
    cursor: pointer !important;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
    -moz-transition: all 0.3s ease-in-out !important;
    -ms-transition: all 0.3s ease-in-out !important;
    -o-transition: all 0.3s ease-in-out !important;
}
.wpwl-label-brand{
display: none !important;
}
.wpwl-control-brand{
display: none !important;
}

.wpwl-apple-pay-button{
    -webkit-appearance: -apple-pay-button !important;
}  

.wpwl-brand-card
{
display: block;
visibility: visible;
position: absolute;
left:0px;
top: 12px;
width: 67px;
z-index: 10;
}

@media only screen and (max-width: 768px) {
    .wpwl-brand-MADA
    {
    top: 44px;
    left: 4px;
    }  
}

.wpwl-brand-MASTER
{
display: none !important;
margin-top: -10;
margin-right: -10;
}
@media only screen and (min-width: 768px) {
.wpwl-brand-MADA
{
top: 20px;
left: 4px;
}
}

.wpwl-brand-VISA
{
display: none !important;
top:14px;
height: 28px;
}