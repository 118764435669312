@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

textarea {
    resize: none;
}

/* For pdf */
.PDFDocument {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.react-pdf__Page {
    margin-top: 5px;
  }
  .react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
  }
  
  .react-pdf__Page__annotations.annotationLayer {
    padding: 5px;
  }
  
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  .scroll {
    height: 300px;
    width: 40px;
    overflow-y: scroll;
    display: inline-block;
  }
  
  .scroll .inner {
    height: 300%;
    width: 100%;
    content: ".";
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  ::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }
  