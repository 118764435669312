input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 4px solid black;
    border-radius: 4px;
    width: 25px;
    height: 25px;
    margin: 0px;
    position: relative;
    background-color: transparent;
}

input[type="checkbox"]:before {
    content: "✓";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-weight: bold;
    color: black;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

input[type="checkbox"]:checked:before {
    opacity: 1;
}