h2{
    font-size: x-large;
    font-family: inherit;
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 3px;
}

h3{
    font-size: large;
    font-family: inherit;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 3px;
}
p{
    font-family: inherit;
}